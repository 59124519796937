import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "banner 名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["name", {
        rules: [{
          required: true,
          message: "请填写banner名称"
        }]
      }],
      expression: "[\n                        'name',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写banner名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      placeholder: "请输入英文"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "banner 图片"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["imgurl", {
        rules: [{
          required: true,
          message: "请上传图片！"
        }]
      }],
      expression: "[\n                        'imgurl',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请上传图片！',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.isFormDisabled(),
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion1,
      memorySize: "1M"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "时间"
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["time", {
        rules: [{
          required: true,
          message: "请填写时间"
        }]
      }],
      expression: "[\n                        'time',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写时间',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDate,
      "show-time": {
        hideDisabledOptions: true,
        defaultValue: [_vm.$moment("00:00:00", "HH:mm:ss"), _vm.$moment("23:59:59", "HH:mm:ss")]
      },
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.onDateChangeTime
    }
  }), _c("span", [_vm._v("（美国时间）")])], 1), _c("a-form-item", {
    attrs: {
      label: "添加类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请填写模块名称"
        }]
      }],
      expression: "[\n                        'type',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写模块名称',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    },
    on: {
      change: function change(value) {
        return _vm.handleBrandChange(value);
      }
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _vm.createForm.getFieldValue("type") == 1 ? _c("a-form-item", {
    attrs: {
      label: "链接地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请填写链接地址"
        }]
      }],
      expression: "[\n                        'content',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写链接地址',\n                                },\n                            ],\n                        },\n                    ]"
    }]
  })], 1) : _vm._e(), _vm.createForm.getFieldValue("type") == 2 || _vm.createForm.getFieldValue("type") == 3 ? _c("a-form-item", {
    attrs: {
      label: "类目"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["parentcategoryid", {
        rules: [{
          required: true,
          message: "请选择一级类目"
        }]
      }],
      expression: "[\n                        'parentcategoryid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择一级类目',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择一级类目"
    },
    on: {
      change: _vm.handleProvinceChange
    }
  }, _vm._l(_vm.seriesCateList, function (it) {
    return _c("a-select-option", {
      key: it.id
    }, [_vm._v(" " + _vm._s(it.name) + " ")]);
  }), 1), _vm.createForm.getFieldValue("type") == 3 ? _c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryid", {
        rules: [{
          required: true,
          message: "请选择二级类目"
        }]
      }],
      expression: "[\n                        'categoryid',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请选择二级类目',\n                                },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px",
      "margin-left": "20px"
    },
    attrs: {
      placeholder: "请选择二级类目"
    }
  }, _vm._l(_vm.secategoryList, function (it) {
    return _c("a-select-option", {
      key: it.id
    }, [_vm._v(" " + _vm._s(it.name) + " ")]);
  }), 1) : _vm._e()], 1) : _vm._e(), _vm.createForm.getFieldValue("type") == 4 ? _c("a-form-item", {
    attrs: {
      label: "品牌"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请选择品牌"
        }]
      }],
      expression: "[\n                        'content',\n                        {\n                            rules: [\n                                { required: true, message: '请选择品牌' },\n                            ],\n                        },\n                    ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      "show-search": "",
      "filter-option": _vm.filterOption
    }
  }, _vm._l(_vm.brandList, function (item) {
    return _c("a-select-option", {
      key: item.id
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.createForm.getFieldValue("type") == 5 ? _c("a-form-item", {
    attrs: {
      label: "搜索词"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请填写搜索词"
        }]
      }],
      expression: "[\n                        'content',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '请填写搜索词',\n                                },\n                            ],\n                        },\n                    ]"
    }]
  })], 1) : _vm._e(), _vm.createForm.getFieldValue("type") == 6 ? _c("a-form-item", {
    attrs: {
      label: "商品ID"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "商品ID"
        }]
      }],
      expression: "[\n                        'content',\n                        {\n                            rules: [\n                                {\n                                    required: true,\n                                    message: '商品ID',\n                                },\n                            ],\n                        },\n                    ]"
    }]
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };